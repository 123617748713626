:root {
  --configuration-color: #ffffff;
  --border-color: #cecece;
  --header-color: #fff;
  --chart-bg-color: #ffffff;
  --chart-grid-line-color: #cecece;
  --chart-line-color: #cecece;
  --chart-legend-color: #4f4f4f;
  --chart-axias-text-color: #8a8a8a;
  --chart-title-color: #4f4f4f;
  --collapsed-icon-color: #1b1f3b;
  --main-bg-color: #f4f4f4;
  --header-bg-color: #ffffff;
  --theme-color: #4f4f4f;
  --primary-color: #f9be00;
  --secretary-color: #8a8a8a;
  --second-chart-bg-color: #fbfbfb;
  --home-img-one: url(/static/svg/Capacity_Installed.svg);
  --battery-img: url(/static/svg/air-conditioner-light.svg);
  --home-img-two: url(/static/svg/Power_Installed.svg);
  --home-img-three: url(/static/svg/PV_Generation.svg);
  --home-img-four: url(/static/svg/Today_Charge.svg);
  --home-img-five: url(/static/svg/TOTAL_DISCHARGED.svg);
  --text-opacity-color: rgba(51, 51, 51, 0.8);
  --bg-opacity-color: rgba(255, 255, 255, 0.75);
  --text-opacity-color-80: rgba(255, 255, 255, 0.8);
  --map-land: #f5f5f3;
  --map-water: #ccd2d3ff;
  --home-text-color: rgba(51, 51, 51, 0.8);
  --home-chart-bg-color: rgba(251, 251, 251, 0.8);
  --home-chart-circle-bg-color: #e2e2e2;
  --home-chart-circle-title-text-color: rgba(0, 0, 0, 0.45);
  --home-chart-circle-title-text-color-total: rgba(0, 0, 0, 0.8);
  --home-more-bg-color: rgba(251, 251, 251, 0.8);
  --home-more-titlt-color: #4f4f4f;
  --home-more-btn-text-color: #4f4f4f;
  --home-more-btn-bg-color: #ffffff;
  --home-more-btn-border-color: #989494;
  --home-data-text-color: rgba(0, 0, 0, 0.8);
  --home-baidu-map-bg-color: #ffffff;
  --home-baidu-map-text-color: rgba(51, 51, 51, 0.8);
  --home-profit-text-color: #8a8a8a;
  --home-data-text-color-two: #8a8a8a;
  --baidu-map-w3: url(/static/images/w3.png);
  --tooltip-bg-color: #3e3f43;
  --tooltip-color-text-one: rgba(51, 51, 51, 0.8);
  --exporting-bg-color: #ffffff;
  --cluster-data-header-bg-color: #f7f8fa;
  --chart-axis-text-color: #989494;
  --bread-high-light-color: rgba(51, 51, 51, 0.9);
  --bread-text-color: rgba(51, 51, 51, 0.8);
  --electrical-flow-chart-flow: "/resource/mxgraph/shapes/12/11.png";
  --electrical-flow-chart-tank: "/resource/mxgraph/shapes/12/10.png";
  --electrical-flow-chart-pv: "/resource/mxgraph/shapes/12/9.png";
  --electrical-flow-chart-otherLoad: "/resource/mxgraph/shapes/12/7.png";
  --electrical-flow-chart-importantLoad: "/resource/mxgraph/shapes/12/6.png";
  --electrical-flow-chart-fuse: "/resource/mxgraph/shapes/12/5.png";
  --electrical-flow-chart-electricityMeter: "/resource/mxgraph/shapes/12/4.png";
  --electrical-flow-chart-container: "/resource/mxgraph/shapes/12/3.png";
  --electrical-flow-chart-powerGrid: "/resource/mxgraph/shapes/12/2.png";
  --electrical-flow-chart-chargingPile: "/resource/mxgraph/shapes/12/1.png";
  --electrical-flow-chart-battery: "/resource/mxgraph/shapes/12/0.png";
  --mxgraph-bg-img: url(/resource/mxgraph/images/mxgraph-content-light.svg);
  --mxgraph-select-border-color: #cccc;
  --mxgraph-input-bg-color: #ffffff;
  --mxgraph-option-bg-color: #ffffff;
  --mxgraph-option-text-color: #ffffff;
  --mxgraph-option-hover-color: #ffffff;
  --mxgraph-select-text-color: #ffffff;
  --mxgraph-split-hover-color: #e0e0e0;
  --mxgraph-dialog-bg-color: rgba(0, 0, 0, 0.15);
  --select-border-color: #cecece;
  --mxgraph-grid-color: #d0d0d0;
  --card-grid-bg: #cecece;
  --fontSize_45: 0.6rem;
}

@titlebg :rgb (56, 98, 148);:export {
  menuText: #b5b1b1;
  menuActiveText: #fff;
  subMenuActiveText: #fff;
  menuBg: #464d55;
  menuHover: #2f3c44;
  subMenuBg: #464d55;
  subMenuHover: #2f3c44;
  sideBarWidth: 4rem;
  containerBg: #323b40;
  borderColor: #323b40;
  breadcrumbBg: #0c4555;
  lighBlue: #03e4eb;
  deepYellow: #f8b90a;
  runStatusNormal: #87ca16;
  runStatusWarning: #ff9500;
  runStatusFault: #ff4a55;
  runStatusOffline: #999;
  menuActiveBg: #fff;
  cardMarginTop: 0.21rem;
}

.main-table {
  font-size: 0.16rem;
}

.main-table table thead .cell {
  height: 100%;
  color: #313131;
  font-size: 0.16rem;
  line-height: 0.21rem;
}

.main-table table tbody .cell {
  height: 100%;
  color: #313131;
  font-size: 0.16rem;
  line-height: 0.21rem;
}

.home-position-list::-webkit-scrollbar {
  width: 0.07rem;
  height: 0.13rem;
  background-color: #323b40;
}

.home-position-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  border-radius: 0.05rem;
  background-color: #323b40;
}

.home-position-list::-webkit-scrollbar-thumb {
  height: 0.05rem;
  border-radius: 0.05rem;
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -webkit-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -moz-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -ms-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  background-color: #323b40;
}

.scrollbox_ant-layout::-webkit-scrollbar {
  width: 0.07rem;
  height: 0.13rem;
  background-color: #323b40;
}

.scrollbox_ant-layout::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  border-radius: 0.05rem;
  background-color: #323b40;
}

.scrollbox_ant-layout::-webkit-scrollbar-thumb {
  height: 0.05rem;
  border-radius: 0.05rem;
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -webkit-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -moz-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  -ms-box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  background-color: #323b40;
}

.dark-white-scroll .ant-table-content::-webkit-scrollbar {
  width: 0.05rem;
  height: 0.05rem;
  background-color: #cecece;
}

.dark-white-scroll .ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  border-radius: 0.03rem;
  background-color: #f4f4f4;
}

.dark-white-scroll.auto-scroll::-webkit-scrollbar {
  width: 0.06rem;
  height: 0.06rem;
  background-color: #cecece;
}

.dark-white-scroll.auto-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.08rem rgba(0,0,0,.1);
  border-radius: 0.03rem;
  background-color: #f4f4f4;
}

.search-list .el-date-editor.el-input {
  width: 40%;
}

.no-data {
  color: var(--theme-color);
}

.no-data.p-l-22 {
  padding-left: 0.29rem;
}

.customer-table .ant-table {
  background-color: #323b40;
}

.customer-table .ant-table td {
  color: #f8b90a;
}

.customer-table .ant-table tr,
.customer-table .ant-table th {
  background-color: #323b40;
}

.customer-table .ant-table .ant-table-tbody td {
  background-color: #323b40;
}

.customer-table .ant-table .ant-table-thead>tr>th,
.customer-table .ant-table .ant-table-tbody>tr>td {
  border-bottom: 0.01rem solid #323b40;
}

.customer-table .ant-table .ant-table-thead>tr>th {
  color: #ccc;
  background-color: #323b40;
}

.customer-table .ant-table .ant-table-tbody tr:hover>td {
  background-color: #fff;
  border-bottom: 0;
}

.customer-table .ant-table:before {
  background-color: #323b40;
}

body .ant-select-multiple .ant-select-selection-item-remove {
  color: #4f4f4f;
}

body .bms-battery-package-data .chuan-battery {
  width: 0.6rem;
  margin-right: 0.04rem;
}

body .bms-battery-package-data .chuan-battery span {
  display: inline-block;
  padding: 0.03rem 0.03rem;
}

body .ant-pagination-options-quick-jumper input {
  border: 0.01rem solid #cecece;
}

body .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
body .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #cecece;
}

body table.table-space {
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

body table.table-space td {
  padding: 0.03rem 0.07rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

body #root .bms-bat-package-data-title {
  display: inline-block;
  padding-bottom: 0.07rem;
  border-bottom: 0.01rem solid #cecece;
  margin-bottom: 0.4rem;
}